.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
      /* scroll */
  padding-top: 3rem;
}

.c-left{
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.c-right .user{
    width: 24rem;
    height: 3rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid yellow;
    border-radius: 8px;
    font-size: 19px;
    color: white   
}

.c-right .user:hover{
    box-shadow: 0 0 9px #fdf901;
}

input{
    background-color: var(--purple)
}
textarea{
    height: 5rem!important;
    background-color: var(--purple)
}

.done{
    font-size: 19px;
    color: green;
}
.not-done{
    font-size: 19px;
    color: red;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 480px) {
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: 40rem;
    }
    .c-right .user{
        width: 16rem;
    }

}